


































































































































































import { Component, Watch } from "vue-property-decorator";
import { mixins } from "vue-class-component";

import {
  DashboardPreviewModel,
  DashboardType,
} from "@/dashboard/models/DashboardModel";
import { AppSection } from "@/shared/models";
import AppSectionAccessMixin from "@/shared/mixins/AppSectionAccessMixin";

@Component
export default class DashboardsView extends mixins(AppSectionAccessMixin) {
  readonly headers = [
    {
      value: "default",
      width: "32px",
      sortable: false,
    },
    {
      text: this.$lang("template.name"),
      value: "name",
      width: "25%",
    },
    {
      text: this.$lang("template.description"),
      value: "description",
    },
    {
      text: this.$lang("template.owner"),
      value: "ownerName",
      width: "15%",
    },
    {
      text: this.$lang("accessType.title"),
      value: "accessType",
      width: "12%",
    },
    {
      text: this.$lang("segment.actions.title"),
      value: "action",
      align: "end",
      width: "10%",
      sortable: false,
    },
  ];

  search = "";
  showOnlyMy = false;
  isDeleteDialogVisible = false;
  deletedDashboard: DashboardPreviewModel | null = null;
  defaultDashboardId: number | null = null;
  prevDefaultDashboardId: number | null = null;

  get dark(): boolean {
    return this.$vuetify.theme.dark;
  }

  get dashboardsType(): DashboardType | undefined {
    return new Map([
      [AppSection.MULTIAPP_DASHBOARDS, DashboardType.MULTI_APP],
      [AppSection.SINGLEAPP_DASHBOARDS, DashboardType.SINGLE_APP],
    ]).get(this.appSection);
  }

  get dashboards() {
    const dashboards = this.$store.state.dashboardStore.dashboards.filter(
      ({ type }: DashboardPreviewModel) => type === this.dashboardsType
    );

    return this.showOnlyMy
      ? dashboards.filter(
          ({ ownerId }: DashboardPreviewModel) =>
            ownerId === this.currentUser.username
        )
      : dashboards;
  }

  get title(): string {
    return this.$lang(`dashboard.title.${this.appSection?.toLowerCase()}`);
  }

  get isDashboardsLoading(): boolean {
    return this.$store.state.dashboardStore.isDashboardsLoading;
  }

  get isDashboardDeleting(): boolean {
    return this.$store.state.dashboardStore.isDashboardDeleting;
  }

  @Watch("title", { immediate: true })
  watchTitle() {
    document.title = this.$lang("documentTitle", this.title);
  }

  @Watch("applicationId", { immediate: true })
  fetchDashboards() {
    if (
      this.$store.getters.isMultiAppMode &&
      this.appSection === AppSection.SINGLEAPP_DASHBOARDS
    ) {
      this.$router.push({
        name: AppSection.MULTIAPP_DASHBOARDS,
      });
    }

    this.$store.dispatch("fetchDashboards");
  }

  @Watch("dashboards", { immediate: true })
  watchDashboards() {
    if (!this.dashboards.length) {
      this.defaultDashboardId = null;
      this.prevDefaultDashboardId = null;

      return;
    }

    const defaultDashboard = this.dashboards.find(
      ({ isDefault }: DashboardPreviewModel) => isDefault
    );

    this.defaultDashboardId = defaultDashboard ? defaultDashboard.id : null;
    this.prevDefaultDashboardId = defaultDashboard ? defaultDashboard.id : null;
  }

  async changeDefaultDashboard(id: number) {
    this.defaultDashboardId =
      this.prevDefaultDashboardId === this.defaultDashboardId
        ? null
        : this.defaultDashboardId;

    await this.$store.dispatch("setDefaultDashboard", {
      id,
      payload: !!this.defaultDashboardId,
    });

    this.prevDefaultDashboardId = this.defaultDashboardId;
  }

  async deleteDashboard() {
    if (!this.deletedDashboard) {
      return;
    }

    await this.$store.dispatch("deleteDashboard", {
      id: this.deletedDashboard.id,
      name: this.deletedDashboard.name,
    });
    this.hideDeleteDialog();
  }

  showDeleteDialog(item: DashboardPreviewModel) {
    this.deletedDashboard = item;
    this.isDeleteDialogVisible = true;
  }

  hideDeleteDialog() {
    this.isDeleteDialogVisible = false;
  }

  handleClickByTableRow(item: DashboardPreviewModel) {
    this.$router.push({
      name: "dashboard_view",
      params: {
        applicationId: this.applicationId,
        dashboardId: String(item.id),
      },
    });
  }

  routeToEditDashboardPage(item: DashboardPreviewModel) {
    this.$router.push({
      name: "dashboard_update",
      params: {
        id: this.applicationId,
        dashboardId: String(item.id),
      },
    });
  }
}
