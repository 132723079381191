var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{staticClass:"pa-4",attrs:{"fluid":""}},[_c('v-row',{staticClass:"align-center"},[_c('v-col',{staticClass:"text-h5 font-weight-bold"},[_vm._v(" "+_vm._s(_vm.title)+" ")]),_c('v-spacer'),(_vm.hasCreateAccess)?_c('v-col',{attrs:{"cols":"auto"}},[_c('v-btn',{staticClass:"ml-4",attrs:{"color":"primary","small":"","fab":"","to":{
          name: 'dashboard_new',
          params: {
            id: _vm.applicationId,
            type: _vm.dashboardsType,
          },
        }}},[_c('v-icon',[_vm._v("add")])],1)],1):_vm._e()],1),_c('v-row',{staticClass:"align-center mt-1"},[_c('v-col',{staticClass:"flex-grow-1",attrs:{"cols":"auto"}},[_c('v-text-field',{staticClass:"pt-0",attrs:{"label":_vm.$lang('shared.search'),"background-color":_vm.dark ? '#1E1E1E' : 'white',"append-icon":"search","hide-details":"","outlined":"","dense":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1),_c('v-col',{attrs:{"cols":"auto"}},[_c('v-checkbox',{staticClass:"mt-0 pt-0",attrs:{"label":_vm.$lang('shared.showOnlyMy'),"hide-details":""},model:{value:(_vm.showOnlyMy),callback:function ($$v) {_vm.showOnlyMy=$$v},expression:"showOnlyMy"}})],1)],1),_c('v-row',{staticClass:"mt-1"},[_c('v-col',{attrs:{"cols":"12"}},[_c('v-data-table',{staticClass:"row-pointer",attrs:{"headers":_vm.headers,"items":_vm.dashboards,"items-per-page":50,"footer-props":{
          itemsPerPageOptions: [25, 50, 100, -1],
        },"loading":_vm.isDashboardsLoading,"search":_vm.search},on:{"click:row":_vm.handleClickByTableRow},scopedSlots:_vm._u([{key:"item.default",fn:function(ref){
        var item = ref.item;
return [_c('v-tooltip',{attrs:{"disabled":_vm.defaultDashboardId === item.id,"right":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
        var on = ref.on;
return [_c('v-radio-group',{model:{value:(_vm.defaultDashboardId),callback:function ($$v) {_vm.defaultDashboardId=$$v},expression:"defaultDashboardId"}},[_c('v-radio',_vm._g({attrs:{"on-icon":"star","off-icon":"mdi-star-outline","color":"orange","disabled":!item.editable,"value":item.id,"title":item.name,"label":null},on:{"click":function($event){$event.stopPropagation();return _vm.changeDefaultDashboard(item.id)}}},on))],1)]}}],null,true)},[_vm._v(" "+_vm._s(_vm.$lang("dashboard.makeFavorite"))+" ")])]}},{key:"item.accessType",fn:function(ref){
        var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.$lang(("accessType." + (item.accessType.toLowerCase()))))+" ")]}},{key:"item.action",fn:function(ref){
        var item = ref.item;
return [(item.editable)?_c('v-tooltip',{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
        var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"bottom":"","icon":"","x-small":""},on:{"click":function($event){$event.stopPropagation();return _vm.routeToEditDashboardPage(item)}}},on),[_c('v-icon',[_vm._v(" mdi-view-dashboard-edit-outline ")])],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$lang("dashboard.edit")))])]):_vm._e(),(item.deletable)?_c('v-tooltip',{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
        var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"ml-2",attrs:{"bottom":"","icon":"","x-small":""},on:{"click":function($event){$event.stopPropagation();return _vm.showDeleteDialog(item)}}},on),[_c('v-icon',[_vm._v(" delete ")])],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$lang("dashboard.delete")))])]):_vm._e()]}}],null,true)})],1)],1),_c('v-dialog',{attrs:{"max-width":"400"},model:{value:(_vm.isDeleteDialogVisible),callback:function ($$v) {_vm.isDeleteDialogVisible=$$v},expression:"isDeleteDialogVisible"}},[_c('v-card',[_c('v-card-title',[_vm._v(_vm._s(_vm.$lang( "dashboard.deleteDialogHeading", (_vm.deletedDashboard && _vm.deletedDashboard.name) || "" )))]),_c('v-card-text',[_c('p',[_vm._v(" "+_vm._s(_vm.$lang( "dashboard.deleteDialogContent", (_vm.deletedDashboard && _vm.deletedDashboard.name) || "" ))+" ")])]),_c('v-card-actions',{staticClass:"justify-end"},[_c('v-btn',{attrs:{"text":""},on:{"click":_vm.hideDeleteDialog}},[_vm._v(" "+_vm._s(_vm.$lang("cancel"))+" ")]),_c('v-btn',{attrs:{"text":"","color":"error","disabled":_vm.isDashboardDeleting,"loading":_vm.isDashboardDeleting},on:{"click":_vm.deleteDashboard}},[_vm._v(" "+_vm._s(_vm.$lang("delete"))+" ")])],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }